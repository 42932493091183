import { datadogLogs } from '@datadog/browser-logs';

import cloneDeepWith from 'lodash/cloneDeepWith';

const tokenStringReplacer = val => {
    if (val && typeof val.replace === 'function') {
        val = val.replace(/access_token=[^&]*/, 'access_token=REDACTED');
        val = val.replace(/id_token=[^&]*/, 'id_token=REDACTED');
        return val;
    }
};

if (import.meta.env.PROD) {
    datadogLogs.init({
        clientToken: 'pubc22a43d75714c0f44e54216c134366fa',
        forwardErrorsToLogs: true,
        site: 'datadoghq.eu',
        sampleRate: 100,
        version: '0.1.2',
        service: 'routeplanning-web',
        env: 'prod',
        beforeSend: obj => cloneDeepWith(obj, tokenStringReplacer),
    });
    datadogLogs.setGlobalContextProperty('account_id', '692043503670');
    datadogLogs.setGlobalContextProperty('team', 'rio-team-transport-two');
    datadogLogs.setGlobalContextProperty('team_identifier', 'rio-team-transport-two');
}
